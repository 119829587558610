<template>
  <div class="container-fluid px-0">
    <div class="row pt-1">
      <div class="col-lg-12">
        <h3 class="mb-2 text-center">PSC (Port State Control) Inspection Company Instructions</h3>
      </div>
      <div class="col-lg-5">
        <form class="form-inline small">
          <input class="form-control form-control-sm w-75" type="search"
                 placeholder="Search Instruction Description ..."
                 aria-label="Search Instruction Description ..." v-model="filters.search">
          <button class="e-btn e-btn-outlined-green e-text-hover-white-light-1" type="submit"
                  style="margin-left: -29px; padding-top: 0.35rem; padding-bottom: 0.35rem;">
            <font-awesome-icon class="e-text-green" icon="search"/>
          </button>
        </form>
      </div>
      <div class="col-lg-7 align-items-end">
        <nav aria-label="...">
          <ul class="pagination justify-content-end text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <template v-if="!loading">
      <company-instruction-tree :company_instructions="companyInstructions"></company-instruction-tree>
    </template>
    <template v-else>
      <app-loading></app-loading>
    </template>

  </div>
</template>
<script>
import loading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import CompanyInstructionTree from "../tree/CompanyInstructionTree.vue";
import AppLoading from "../../../elements/AppLoading.vue";

export default {
  name: "StaffPscCompanyInstruction",
  components: {AppLoading, CompanyInstructionTree, loading},
  data(){
    return {
      loading: false,
      filters: {
        type:'psc',
        page: 1,
        perPage:10,
        search: '',
        sentOnly:true,
      }
    }
  },
  mixins: [PaginationMixin],
  computed:{
    ...mapGetters(['companyInstructions'])
  },
  methods: {
    ...mapActions(['getCompanyInstructions']),
    async initialize(){
      this.loading=true;
      await this.getCompanyInstructions(this.filters)
      this.loading=false;
    }
  },
  async created(){
    this.loadIns=_.debounce(this.initialize,750)
    await this.initialize()
  },
  watch:{
    "filters.search"(){
      this.loadIns()
    }
  }
}
</script>

<style scoped lang="scss">
.c-tbl-padded-left-10 {
  margin-left: 10rem;
  width: calc(100% - 10rem);

  td:nth-child(1) {
    width: 15%;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 10%;
  }

  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7){
    width: 7%;
  }
}
</style>
